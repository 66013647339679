import styled from "styled-components";

export const Container = styled.div`

    @media (max-width: 1024px){
        width: 100%;
        margin: 0;
        border-radius: 0;
        height: auto;


    }

    overflow-y: scroll;
    ::-webkit-scrollbar {
    display: none
    }

    height: 60vh;
    width: 20%;
    background: #ECECEC;
    -webkit-box-shadow: -1px 5px 15px -1px rgba(0,0,0,0.44); 
    box-shadow: -1px 5px 15px -1px rgba(0,0,0,0.44);
    border-radius: 1.5rem;
    text-align: center;
    padding: 1rem;

    .cover__img{
        width:100%;
    }
    .avatar__img{
    width:60%;
    margin-top: -25%;
    max-width: 180px;
    height: auto;
    }

    h1{
        font-family: 'Spartan', sans-serif;
        font-size: 1.2rem;
        color: #272424;
        text-transform: uppercase;
        margin-top: 10px;
        padding: 10px;
    }

    p {
        font-family: 'Spartan', sans-serif;
        font-weight:500;
        font-size: 1rem;
        color: #272424;
        margin-top: 10px;
    }

    hr{ 
        width: 70%;
        border-color: #DCDCDC;
        margin: 10px auto;
    }

    .profile__icon{
        display: flex;
        justify-content:center;

        i {
            margin: 0 10px;
            font-size: 2rem;
            background: linear-gradient(242deg, rgba(0,128,55,1) 0%, rgba(0,186,186,1) 50%, rgba(0,194,203,1) 100%);
            text-decoration: none;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color:transparent;
        }
    }

    .cv{  
        text-align: center;
        margin-top: 2.5rem;
        display: inline-block;
        color: #fff;
        text-decoration: none;
        background: linear-gradient(242deg, rgba(0,128,55,1) 0%, rgba(0,186,186,1) 50%, rgba(0,194,203,1) 100%);    
        border: 0;
        cursor: pointer;
        padding: 1rem;
        font-size: 1rem;
        font-family: Spartan, sans-serif;
        font-weight:700;
        border-radius: 0.75rem;
        transition: filter 0.2s;

        &:hover{ 
            filter: brightness(0.9);
        }
    }

`
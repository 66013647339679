import { MainCard } from "../MainCard";
import { Container } from "./style";

export function Education() {
  return (
    <MainCard>
      <Container>
        <h2>
          <span>.e</span>ducation and courses
        </h2>
        <div className="content">
          <h3>
            React developer bootcamp <span>@ Rocketseat</span>
          </h3>
          <p>From 08/2021 until 11/2021</p>
          <p>
            Three months of Bootcamp focused on front-end development using
            React and Typescript. The course covers the basics of React (how to
            create components, state management, context API, and hooks),
            Next.JS, and evolve in more complex subjects, like the JAM stack,
            how to optimize your React application and other soft skills.
          </p>
          <hr />
          <h3>
            The Complete Web Development Bootcamp
            <span> @ Udemy by Angela Yu</span>
          </h3>
          <p>From 03/2020 until 06/2020</p>
          <p>
            The course focused on the base of web development, with a deep dive
            in HTML, CSS, and Javascript and a brief introduction to Node.js,
            REST API, MongoDB and, React.
          </p>
          <hr />
          <h3>
            Flutter development with Dart
            <span> @ Udemy by Angela Yu</span>
          </h3>
          <p>From 06/2020 until 08/2020</p>
          <p>
            Course developed along with the Google Flutter team. It covers the
            fundamentals of Dart and Flutter, and focus in create real life
            projects, handling API calls, integration with Firebase and state
            management.
          </p>
        </div>
      </Container>
    </MainCard>
  );
}


import { Home } from './pages/home';
import { GlobalStyle } from './styles/global';

function App() {
  return (
    <>
    <Home/>
    <GlobalStyle/>
    </>
    
  );
}

export default App;

import { ReactNode } from "react";
import { Container } from "./styles";

interface MainCardProps {
  children: ReactNode;
}

export function MainCard(props: MainCardProps) {
  return (
    <Container initial={{ y: "-100vh" }} animate={{ y: 0 }}>
      {props.children}
    </Container>
  );
}


import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom"
import { About } from "../components/About/about"
import { CardInfo } from "../components/CardInfo/cardinfo"
import { Education } from "../components/Education"
import { Navbar } from "../components/Navbar/navbar"
import { Projects } from "../components/Projects"

import {Container} from '../styles/home'

export function Home(){
    useHistory();
    const location = useLocation();

    return (
        <Container>
            <Navbar/>
                <CardInfo/>
                <Switch location={location} key={location.key}>
                    <Route path="/" exact component={About}></Route>
                    <Route path="/education"  component={Education}/>
                    <Route path="/projects"  component={Projects}/>
                    <Redirect to="/" />
                </Switch>
        </Container>
    )
}
import styled from "styled-components";

export const Container = styled.div`
  .content {
    @media (max-height: 768px) {
      max-height: 600px;
    }
    height: 90%;
    overflow: scroll;
  }
  h3 {
    color: #008037;
    span {
      color: #707070;
    }
  }

  p {
    margin-top: 0.5rem;
    font-family: Roboto, sans-serif;
    line-height: 25px;
  }

  hr {
    width: 60%;
    margin: 20px auto;
    opacity: 0.7;
  }
`;

import styled from 'styled-components'

export const Container = styled.nav `
@media (max-width: 1024px){
        position:fixed;
	    bottom:0;
	    left:0;
	    right:0;
	    z-index:1000;
        width: 100%;
        height: 60px;
        flex-direction: row;
        border-radius: 0;
        .menu__item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        text-decoration: none;
        color: #EBEBEB;
        transition: filter 0.2s;
    }
    

}   
    height: 60vh;
    width:80px;
    background: rgb(0,128,55);
    background: linear-gradient(242deg, rgba(0,128,55,1) 0%, rgba(0,186,186,1) 50%, rgba(0,194,203,1) 100%);
    font-size: 1rem;
    border-radius: 0.75rem;
    -webkit-box-shadow: -1px 7px 15px -1px rgba(0,0,0,0.44); 
    box-shadow: -1px 7px 15px -1px rgba(0,0,0,0.44);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 1rem;

    .menu__item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        text-decoration: none;
        color: #EBEBEB;
        transition: filter 0.2s;

        

        i {
            font-size: 2rem;
            transition: filter 0.2s;
            &:hover{
            filter: brightness(0.9)
        }
        }
    }

    .menu__item--active{

    i{
        background-color: #00C2CB;
        padding: 10px;
        border-radius: 0.30rem;
    }
    }



`
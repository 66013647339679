import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  .skills {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    @media (max-width: 1024px) {
      margin-top: 0;
      flex-direction: column;
    }
  }
  .content__skills {
    h2 {
      margin-bottom: 2rem;
    }
    margin-top: 2rem;
    .badges {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      img {
        margin: 0 1rem;
        height: 100%;
        max-height: 85px;
        transition: all 0.3s ease;

        &:hover {
          -webkit-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transform: scale(1.3);
        }
      }
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;

  .content {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    .projectCard {
      @media (max-width: 1024px) {
        width: 100%;
        margin: 1rem auto;
      }
      width: 25%;
      border-radius: 0.75rem;
      -webkit-box-shadow: -1px 5px 15px -1px rgba(0, 0, 0, 0.34);
      box-shadow: -1px 5px 15px -1px rgba(0, 0, 0, 0.34);
      transition: all 0.2s ease-in-out;
      position: relative;

      .card_description {
        border-radius: 0.75rem;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;
        height: 100%;
        width: 100%;
        background-color: #00c2cb;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        transition: opacity 0.2s, visibility 0.2s;
        font-weight: 700;
        padding-top: 30%;
        i {
          font-size: 2rem;
          margin: 0 auto;
        }
        a {
          color: #fff;
        }
      }

      &:hover .card_description {
        visibility: visible;
        opacity: 0.9;
      }
    }

    img {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      height: auto;
      width: 100%;
      max-height: 217px;
      object-fit: cover;
    }

    .card_body {
      padding: 1rem 1rem;
      line-height: 2rem;

      span {
        background: linear-gradient(
          242deg,
          rgba(0, 128, 55, 1) 0%,
          rgba(0, 186, 186, 1) 50%,
          rgba(0, 194, 203, 1) 100%
        );
        padding: 0.2rem 0.4rem;
        border-radius: 0.55rem;
        color: #fff;
        font-weight: 700;
        font-size: 0.65rem;
      }
    }
  }

  h2 {
    margin-bottom: 1rem;
    span {
      color: #008037;
    }
  }
  h3 {
    color: #333333;
  }

  p {
    margin-top: 0.5rem;
    font-family: Roboto, sans-serif;
    line-height: 25px;
  }

  hr {
    width: 60%;
    margin: 20px auto;
    opacity: 0.7;
  }
  .more_projects {
    text-align: center;
    a {
      text-align: center;
      margin-top: 2.5rem;
      display: inline-block;
      color: #fff;
      text-decoration: none;
      background: linear-gradient(
        242deg,
        rgba(0, 128, 55, 1) 0%,
        rgba(0, 186, 186, 1) 50%,
        rgba(0, 194, 203, 1) 100%
      );
      border: 0;
      cursor: pointer;
      padding: 1rem;
      font-size: 1rem;
      font-family: Spartan, sans-serif;
      font-weight: 700;
      border-radius: 0.75rem;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`;

import { Container } from "./styles";

import htmlImg from "../../assets/html5.svg";
import cssImg from "../../assets/css.svg";
import jsImg from "../../assets/js.svg";
import reactImg from "../../assets/react.svg";
import nextImg from "../../assets/next.svg";
import { MainCard } from "../MainCard";

export function About() {
  return (
    <MainCard>
      <Container>
        <h2>
          <span>.a</span>bout me
        </h2>
        <div className="intro">
          <p>
            Hey, my name is João, I'm 23 years old, and I have been studying web
            development since I was 20.
            <br /> I always was curious about coding, so in 2017 I bought my
            first web development course. After that, I never stopped learning
            about programming.
            <br />
            Now, I finally decided to take the next step and begin my career as
            a front-end developer. Yes, I know my portfolio it's a little bit
            dry but I'm working every day to improve it.
            <br />
            My best skills are HTML, CSS, and Javascript. I'm in the middle of a
            Bootcamp studying React and I have a big crush on Flutter.
          </p>
          <div className="skills">
            <div className="content__skills">
              <h2>
                <span>.w</span>hat I'm good at:{" "}
              </h2>
              <div className="badges">
                <img src={htmlImg} alt="HTML" />
                <img src={cssImg} alt="CSS" />
                <img src={jsImg} alt="Javascript" />
              </div>
            </div>
            <div className="content__skills" id="learing">
              <h2>
                <span>.w</span>hat I'm learing:{" "}
              </h2>
              <div className="badges">
                <img src={reactImg} alt="React" />
                <img src={nextImg} alt="next" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MainCard>
  );
}

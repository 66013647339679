import { Container } from "./styles";

import coverImg from "../../assets/cover.png";
import avatarImg from "../../assets/rick.png";

export function CardInfo() {
  return (
    <Container>
      <img className="cover__img" src={coverImg} alt="" />
      <img src={avatarImg} className="avatar__img" alt="That's my pic!" />
      <h1>João Henrique Trindade</h1>
      <p>Front end developer</p>
      <hr />
      <div className="profile__icon">
        <a
          href="https://www.linkedin.com/in/joaohtrindade/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          href="https://github.com/rickparapinski"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
        <a
          href="mailto:joao@htrindade.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-envelope"></i>
        </a>
      </div>
      <a
        href="/Resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="cv"
      >
        Download CV
      </a>
    </Container>
  );
}

import styled from 'styled-components'

export const Container = styled.div`   
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:1024px){
        height:100%;
        flex-direction: column;
        padding: 0%;
        margin-top: 0;
        margin-bottom: 10%;
    }
    
`

import { NavLink } from "react-router-dom";
import { Container } from "./styles";

export function Navbar () {
    return (
        <Container>
            <NavLink to="/" exact className="menu__item" activeClassName="menu__item--active" data-tooltip="Home">
            <i className="fas fa-user-alt"></i>
            </NavLink>
            <NavLink to="/education" className="menu__item" activeClassName="menu__item--active"  data-tooltip="Education">
            <i className="fas fa-book-reader"></i>
            </NavLink>
            <NavLink to="/projects" className="menu__item" activeClassName="menu__item--active" data-tooltip="Projects">
            <i className="fas fa-code"></i>
            </NavLink>
        </Container>
    )
}
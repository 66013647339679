import { Container } from "./style";
import stImg from "../../assets/st.jpg";
import toDoImg from "../../assets/todo.jpg";
import letmeAskImg from "../../assets/letmeask.jpg";
import { MainCard } from "../MainCard";

export function Projects() {
  return (
    <MainCard>
      <Container>
        <h2>
          <span>.m</span>ain projects
        </h2>
        <div className="content">
          <div className="projectCard">
            <img src={stImg} alt="Tax calculator" />
            <div className="card_body">
              <h3>Tax calculator</h3>
              <p>
                My first project, used to calculate tax amount for products.
              </p>
              <span>HTML</span> <span>JS</span> <span>PHP</span>
              <div className="card_description">
                <a href="https://github.com/rickparapinski/calculadoraST">
                  <i className="fas fa-external-link-square-alt"></i>
                  <br />
                  Click here to acess the project
                </a>
              </div>
            </div>
          </div>
          <div className="projectCard">
            <img src={toDoImg} alt="2Do" />
            <div className="card_body">
              <h3>DT Money</h3>
              <p>React app used to track and categorize income and expenses.</p>
              <span>HTML</span> <span>JS</span> <span>React</span>
              <span>Typescript</span>
              <div className="card_description">
                <a href="https://github.com/rickparapinski/dt-money">
                  <i className="fas fa-external-link-square-alt"></i>
                  <br />
                  Click here to acess the project
                </a>
              </div>
            </div>
          </div>
          <div className="projectCard">
            <img src={letmeAskImg} alt="Let me ask" />
            <div className="card_body">
              <h3>LetMeAsk</h3>
              <p>
                App to create rooms for questions. Uses Firebase Auth and
                Realtime Databse to store the data. Build with React.
              </p>
              <span>HTML</span> <span>JS</span> <span>React</span>{" "}
              <span>Firebase</span>
              <div className="card_description">
                <a href="https://github.com/rickparapinski/letmeask">
                  <i className="fas fa-external-link-square-alt"></i>
                  <br />
                  Click here to acess the project
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="more_projects">
          <a href="https://github.com/rickparapinski?tab=repositories">
            Hey, I have more procjects in my Github!{" "}
            <i className="fab fa-github-square"></i>
          </a>
        </div>
      </Container>
    </MainCard>
  );
}

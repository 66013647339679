import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
  height: 60vh;
  width: 50%;
  background: #ececec;
  -webkit-box-shadow: -1px 5px 15px -1px rgba(0, 0, 0, 0.44);
  box-shadow: -1px 5px 15px -1px rgba(0, 0, 0, 0.44);
  border-radius: 0.75rem;
  margin-left: 1rem;
  padding: 3rem;
  color: #707070;
  overflow: scroll;
  overflow-x: hidden;

  h2 {
    font-family: Spartan, sans-serif;
    margin-bottom: 2rem;
    span {
      color: #008037;
    }
  }

  p {
    font-family: Roboto, sans-serif;
    line-height: 25px;
  }
`;
